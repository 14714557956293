import React from 'react';
import { breakpoints, spacing } from '@naf/theme';
import styled, { css } from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { FeaturedVehicleTestResultBlock } from '../../../../types/featuredVehicleResultBlockType';
import { VehicleTestData } from '../../../../types/showcaseBlockType';
import { CombinedResultCard } from './Testshowcase/CombinedResultCard';
import BlockContent from '../block-content/BlockContent';
import { ElPrixContextData } from '../../../../types/ElPrixContextData';

type Props = {
  block: FeaturedVehicleTestResultBlock;
};

export type VehicleResultProps = {
  data: VehicleTestData;
  context: Pick<ElPrixContextData, 'season' | 'testDate' | 'tests'>;
};

export const FeaturedVehicleTestResult: React.FC<Props> = ({ block }) => {
  const { title, featuredVehicleResults, content } = block;
  const resultCount = featuredVehicleResults.length;
  const vehicleData: VehicleResultProps[] = featuredVehicleResults
    .map(({ testReference }) => ({
      context: {
        testDate: testReference.testDate,
        season: testReference.season,
        tests: testReference.tests,
      },
      data: testReference.data,
    }))
    .filter(({ data }) => data);

  return (
    <Layout resultCount={resultCount}>
      <TextContent>
        <Text variant={TextVariant.Header2}>{title}</Text>
        <BlockContent value={content} />
      </TextContent>
      <ResultContent>
        {vehicleData.map(({ context, data }) => (
          <CombinedResultCard key={`${data.vehicleId}_${context.season}`} data={data} context={context} />
        ))}
      </ResultContent>
    </Layout>
  );
};

const Layout = styled.div<{ resultCount: number }>`
  display: flex;
  ${({ resultCount }) =>
    resultCount === 1
      ? css`
          gap: ${spacing.space32};
          > div {
            width: 50%;
          }
        `
      : css`
          flex-direction: column;
          gap: ${spacing.space24};
        `}

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    > a,
    div {
      flex-basis: 100%;
      flex-grow: 1;
      width: unset;
    }
  }
`;

const TextContent = styled.div`
  > h2 {
    margin: 0 0 ${spacing.space16} 0;
  }

  > p {
    margin: 0;
  }
`;

const ResultContent = styled.div`
  display: flex;
  gap: ${spacing.space8};
  flex-basis: 22rem;

  > a,
  > div {
    flex-basis: 0;
    flex-grow: 1;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;

    > a {
      flex-basis: 100%;
      flex-grow: 1;
    }
  }
`;
